.paddle-head-search-ac {
    transition: all 0.5s cubic-bezier(0.7, 0.3, 0.1, 1);
    width: 300px;

    .ant-input-prefix {
        color: @primary-color;
        margin-left: 4px;
        margin-right: 8px;
        border-radius: 0px;
    }

    &.ant-select-open {
        width: 800px;
    }

    .ant-input {
        height: 22px;
        line-height: 22px;
    }

    .ant-input-affix-wrapper {
        margin-bottom: 5px;

        &-borderless {
            border: 1px solid rgba(255, 255, 255, 0.5);
            border-radius: 0;

            .ant-input-prefix {
                color: #fff;
            }
        }
    }

    .ant-input-affix-wrapper-focused {
        border-radius: 0 !important;
    }

    .ant-input-borderless {
        color: #fff;
    }

    .ant-input-borderless::-webkit-input-placeholder,
    .ant-input-borderless::placeholder {
        color: rgba(254, 253, 253, 0.5);
    }
}

.paddle-head-search-ac-overlay {
    padding: 0 0 4px 0;

    &-ellipsis {
        display: inline-block;
        flex: none;
        overflow: hidden;
        color: #666;
        white-space: nowrap;
        text-overflow: ellipsis;
        word-break: break-all;
    }

    .ant-select-item {
        padding: 5px 40px;

        &,
        & a {
            color: #333;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0;
        }
    }

    .ant-select-item-group {
        padding: 23px 40px 8px 40px;

        &,
        & a {
            color: #999;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0;
        }
    }

    &-more {
        color: @primary-color;
        padding: 12px 40px;
    }

    &-divider {
        border-top: 1px solid #dadcdf;
        margin: 24px 40px 0 40px;
    }

    &-item {
        align-items: center;
        display: flex;
    }

    &-item-title {
        color: #666;
        display: inline-block;
        flex: none;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 180px;
        white-space: nowrap;
        word-break: break-all;

        em {
            color: #000;
            font-weight: 700;
        }
    }

    &-item-divider {
        border-left: 1px solid #dadcdf;
        display: inline-block;
        height: 14px;
    }

    &-item-type {
        padding-left: 18px;
    }

    &-loading-wrap {
        margin: 24px 40px;
    }
}

.paddle-header-menu-popup {
    .ant-select-dropdown {
        border-radius: 0;
    }
}

.paddle-head-search-ac-overlay-type2 {
    .ant-select-item-group {
        &,
        & a {
            color: #000;
        }
    }
}

// @media screen and (max-width: 1380px) {
//     .paddle-head-search-ac {
//         max-width: 194px;
//     }
// }
