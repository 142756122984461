/**
 * @file 页脚样式
 * @author FengGuang(fengguang01@baidu.com)
 */

.paddle-footer {
	background: #000;
	display: block;
	min-height: 403px;

	&,
	& a {
		color: #999;
	}

	&-container {
		max-width: @main-max-width;
		min-height: 353px;
		margin: 0 auto;
		overflow: hidden;
		padding: 85px 0;
	}

	&-group {
		width: 191px;
		display: inline-block;
		vertical-align: top;
		overflow: hidden;
	}

	&-group-title {
		font-size: 18px;
	}

	&-item-list {
		float: left;
		margin-top: 25px;
	}

	&-item,
	&-item a {
		color: #f0f0f0;
		font-weight: 400;
		font-size: 14px;
		line-height: 36px;
	}

	&-qr-wrap {
		float: right;
		margin-bottom: -11px;
		margin-right: -55px;
	}

	&-qr-item {
		display: inline-block;
		margin-right: 55px;
		vertical-align: top;
		width: 180px;
		text-align: center;
	}

	&-qr-context {
		background-size: contain;
		background-repeat: no-repeat;
		display: inline-block;
		height: 140px;
		overflow: hidden;
		width: 140px;

		&-img {
			opacity: 0;
			width: 100%;
		}
	}

	&-qr-title {
		color: #7b7b7b;
		font-size: 14px;
		line-height: 14px;
		margin-top: 14px;
		text-align: center;
		vertical-align: top;
	}

	&-qr-subtitle {
		font-size: 12px;
	}

	&-bottom {
		background: #000;
		border-top: 1px solid #242424;
		color: #999;
		font-size: 14px;
		height: 50px;
		line-height: 50px;
		text-align: center;
	}

	.beian {
		margin-left: 100px;
	}

	.footer-mark-number {
		margin-left: 80px;
	}

	.record-link-style:hover {
		color: rgba(153, 153, 153, .8);
	}
}
