/**
 * @file 公用样式
 * @author FengGuang(fengguang01@baidu.com)
 */
@main-max-width: 1400px;

html,
body {
    min-width: @main-max-width;
    margin: 0;
    padding: 0;
}

.page {
    font-size: 14px;
    font-weight: 400;
}

.paddle-context-wrap {
    position: relative;
}

.paddle-context-link-more {
    bottom: 0;
    position: absolute;
    right: 0;

    a {
        font-size: 18px;
        font-weight: 400;
        color: #000;
        letter-spacing: 0;
        line-height: 26px;
    }
}

.paddle-context-h2 {
    color: #000;
    font-size: 30px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 45px;
    margin-top: 54px;
}

.paddle-context-h3 {
    color: #666;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    margin-top: 15px;

    a {
        color: @primary-color;
        margin-left: 8px;
    }
}

.paddle-context-h4 {
    color: #666;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 27px;
    margin-top: 10px;

    a {
        color: @primary-color;
        margin-left: 8px;
    }
}

.clear-both:after {
    content: '';
    display: block;
    line-height: 0;
    clear: both;
}

.clearfix() {
    display: block;
    zoom: 1;
    &:after {
        content: '';
        display: block;
        font-style: initial;
        height: 0;
        clear: both;
        visibility: hidden;
    }
}

.link-button {
    border: none;
    display: inline;
    margin: 0;
    font-weight: 500;
    padding: 0;

    color: @primary-color;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
    -webkit-text-decoration-skip: objects;
}

.link-button:active,
.link-button:hover {
    text-decoration: none;
    outline: 0;
}

.link-button:hover {
    color: #515eed;
}

.center-text {
    display: flex;
    align-items: center;
    justify-content: center;
}
